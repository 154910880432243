import React, { useEffect, useState } from 'react';
import './home.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';

// import headerLogo from '../assets/images/logo/headerLogo.png';
// import headerLogo from '../assets/images/logo/wsfHeaderLogo.gif';
import footerLogo from '../assets/images/logo/footerLogo.png';
import Solution from './solution/Solution';
import Services from './services/Services';
import AboutUs from './company/Company';
import HelpCenter from './helpCenter/HelpCenter';
// import { getActiveMenu } from '../utils/utils';

const Header = () => {
    const navigate = useNavigate();
    // const location = useLocation();
    const [header, setHeader] = useState(false);
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [solutionMenuVisible, setSolutionMenuVisible] = useState(true);
    const [servicesMenuVisible, setServicesMenuVisible] = useState(true);
    const [companyMenuVisible, setCompanyMenuVisible] = useState(true);
    const [helpCenterMenuVisible, setHelpCenterMenuVisible] = useState(true);
    const [activeMenu, setActiveMenu] = useState('');

    useEffect(() => {
        const changeBackground = () => {
            if (window.scrollY >= 90) {
                setHeader(true);
            } else {
                setHeader(false);
            }
        };

        window.addEventListener('scroll', changeBackground);
        return () => {
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);

    // useEffect(() => {
    //     const path = location.pathname;
    //     setActiveMenu(getActiveMenu(path));
    // }, [location.pathname]);

    const handleLinkClick = (url) => {
        setSolutionMenuVisible(false);
        setServicesMenuVisible(false);
        setCompanyMenuVisible(false);
        setHelpCenterMenuVisible(false);
        setIsNavExpanded(false);
        setActiveMenu(url);
        navigate(url);
    };

    return (
        <>
            {/* <!-- ======= Header ======= --> */}
            {/* <header className="header fixed-top"> */}
            <header className={header ? 'header fixed-top' : 'header'}>
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo d-flex align-items-center">
                        <img src={footerLogo} alt="" />
                    </Link>

                    <nav className={isNavExpanded ? 'navbar-mobile' : 'navbar'} id="navbar">
                        {/* <nav className="navbar-mobile navbar" id="navbar"> */}
                        <ul>
                            <li className={`dropdown solutionDropdown ${activeMenu === 'ecommerce-solution' || activeMenu === 'edtech' || activeMenu === 'small-to-medium-business' || activeMenu === 'corporate' || activeMenu === 'consultancy' ? 'active-menu' : ''}`}
                                onMouseEnter={() => setSolutionMenuVisible(true)}
                                onMouseLeave={() => setSolutionMenuVisible(true)}
                            >
                                <NavLink className="active-menu-a" to="/solution" onClick={() => {
                                    setSolutionMenuVisible(false);
                                    handleLinkClick('solution');
                                }}>
                                    <span>Solution</span> <i className="bi bi-chevron-down"></i>
                                </NavLink>
                                <ul className={solutionMenuVisible ? '' : 'd-none'}>
                                    <div className="row">
                                        <Solution handleLinkClick={handleLinkClick} />
                                    </div>
                                </ul>
                            </li>

                            <li className={`dropdown servicesDropdown ${activeMenu === 'web-application-details' || activeMenu === 'software-development-details' || activeMenu === 'mobile-application-details' || activeMenu === 'creative-details' || activeMenu === 'digital-marketing-and-seo-details' | activeMenu === 'domain-and-hosting-details' ? 'active-menu' : ''}`}
                                onMouseEnter={() => setServicesMenuVisible(true)}
                                onMouseLeave={() => setServicesMenuVisible(true)}
                            >
                                <NavLink className="active-menu-a" to="web-application-details" onClick={() => {
                                    setServicesMenuVisible(false);
                                    handleLinkClick('web-application-details');
                                }}>
                                    <span>Services</span> <i className="bi bi-chevron-down"></i>
                                </NavLink>
                                <ul className={servicesMenuVisible ? '' : 'd-none'}>
                                    <div className="row">
                                        <Services handleLinkClick={handleLinkClick} />
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <NavLink to="/portfolio" onClick={() => handleLinkClick('/portfolio')}>Portfolio</NavLink>
                            </li>                            

                            <li className={`dropdown aboutUsDropdown ${activeMenu === 'our-clients' || activeMenu === 'our-technology-partners' || activeMenu === 'about-us' || activeMenu === 'career' || activeMenu === 'testimonials' || activeMenu === 'faq' || activeMenu === 'our-teams' ? 'active-menu' : ''}`}
                                onMouseEnter={() => setCompanyMenuVisible(true)}
                                onMouseLeave={() => setCompanyMenuVisible(true)}
                            >
                                <NavLink to="/company" onClick={() => {
                                    setCompanyMenuVisible(false)
                                    handleLinkClick('company');
                                }}>
                                    <span>Company</span> <i className="bi bi-chevron-down"></i>
                                </NavLink>
                                <ul className={companyMenuVisible ? '' : 'd-none'}>
                                    <div className="row">
                                        <AboutUs handleLinkClick={handleLinkClick} />
                                    </div>
                                </ul>
                            </li>

                            <li className={`dropdown ${activeMenu === 'knowledged-base' || activeMenu === 'contact-us' ? 'active-menu' : ''}`}
                                onMouseEnter={() => setHelpCenterMenuVisible(true)}
                                onMouseLeave={() => setHelpCenterMenuVisible(true)}
                            >
                                <NavLink to="/help-center" onClick={() => {
                                    setHelpCenterMenuVisible(false)
                                    handleLinkClick('help-center');
                                }}>
                                    <span>Help Center</span> <i className="bi bi-chevron-down"></i>
                                </NavLink>
                                <ul className={helpCenterMenuVisible ? 'helpDropdown' : 'd-none helpDropdown'}>
                                    <div className="row">
                                        <HelpCenter handleLinkClick={handleLinkClick} />
                                    </div>
                                </ul>
                            </li>
                        </ul>

                        <i
                            onClick={() => {
                                setIsNavExpanded(!isNavExpanded)
                            }}
                            className={isNavExpanded ? 'bi bi-x-circle mobile-nav-toggle' : 'bi bi-list mobile-nav-toggle'}
                        ></i>

                    </nav>
                </div>
            </header>
        </>
    );
};

export default Header;