import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../../api/api";

function CategorySidebar() {
  const { categorySlug } = useParams();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    api.get(`/blogs/${'knowledge_based'}/sub-categories/${categorySlug}`).then((res) => {
      setCategories(res.data.data.categories);
    });
  }, [categorySlug]);

  return (
    <>
      <div className="sidebar category-sidebar">
        {categories.map((category) => (
          category.parent_id == null ?
            <div key={category.id}>
              <h3 className={`sidebar-title ${categorySlug === category.slug ? 'active-category' : ''}`} >
              <Link to={`/help-center/knowledged-base/category/${category.slug}`}>
                {category.name}
              </Link>
            </h3>
            </div>
            
            :
              <div className="sidebar-item categories" key={category.id}>
                <h5 className={`category ml-3 ${categorySlug === category.slug ? 'active-category' : ''}`}>
                  <Link to={`/help-center/knowledged-base/category/${category.slug}`}>
                    {category.name}
                  </Link>
                </h5>
              </div>
        ))}
      </div>
    </>
  );
}

export default CategorySidebar;
