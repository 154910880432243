import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/utils";
import api from "../../../api/api";

import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { AiFillCalendar } from "react-icons/ai";
import { AiFillExperiment } from "react-icons/ai";
import { VscTerminalLinux } from "react-icons/vsc";
import { FaEye } from "react-icons/fa";

function JobPostList() {
  const [jobPost, setJobPost] = useState([]);
  useEffect(() => {
    api.get(`/career`).then((res) => {
      if (res.status === 200) {
        setJobPost(res.data.data);
      }
    });
  }, []);

  return (
    <>
      {jobPost.map((item, index) => (
        <div className="col-lg-6 col-md-6" key={index}>
          <div className="cardBox" style={{ marginTop: "10px" }}>
            <ul className="row">
              <div className="col-md-12">
                <li className="name jobTitle" style={{ paddingBottom: "5px" }}>
                  <b>
                    {" "}
                    <Link to={`/company/career/job-details/${item.slug}`}>
                      {item.job_title}
                    </Link>{" "}
                  </b>
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <BsFillJournalBookmarkFill />
                  </span>
                  {item.job_uid}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <ImLocation2 />
                  </span>
                  {item.office_location}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <VscTerminalLinux />
                  </span>
                  {item.employment_status}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <AiFillExperiment />
                  </span>
                  {item.year_of_experience}
                </li>
                <li style={{ paddingBottom: "5px" }}>
                  <span style={{ paddingRight: "10px" }}>
                    {" "}
                    <AiFillCalendar />
                  </span>
                  {formatDate(item.application_deadline)}

                  <Link
                    to={`/company/career/job-details/${item.slug}`}
                    style={{ float: "right", fontSize: "25px" }}
                  >
                    <FaEye />
                  </Link>
                </li>
              </div>
              <div className="col-md-2">
                {/* <img src="assets/img/team/team-1.jpg" className="img-fluid" height={'230px'} alt="" /> */}
              </div>
            </ul>
          </div>
        </div>
      ))}
    </>
  );
}

export default JobPostList;
