import React from "react";
import { useState } from "react";
import "../../home.css";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import api from "../../../api/api";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");

  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactUs = { name, phone, email, subject, message };

    setIsPending(true);

    await api.post(`/contact_us/store`, contactUs)
      .then(response => {
        if (response.status === 200) {
          swal({
              title: "Success!",
              text: "Thanks for contacting with us. We will contact with you soon.",
              icon: "success",
              button: "OK!",
              timer: 1500,
          });

          // Reset form fields
          setName('');
          setPhone('');
          setEmail('');
          setSubject('');
          setMessage('');
          navigate('');
        } else {
          swal({
            title: "Failed!",
            text: "Please Check Required Fields.",
            icon: "error",
            button: "OK!",
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else {
          console.log(error);
        }
      })   
    setIsPending(false);
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="php-email-form contactUs"
        style={{ backgroundColor: "var(--color-light-blue-opacity)" }}
      >
        <header className="section-header">
          <p className="text-white">Tell us more about your project</p>
        </header>
        <div className="row gy-4">
          <div className="col-md-12">
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control text-white"
              placeholder="Your Full Name "
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
            {errors.name && <div className="text-danger">{errors.name}</div>}
          </div>
          <div className="col-md-12 ">
            <input
              type="number"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control text-white"
              placeholder="Your Phone Number"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
            {errors.phone && <div className="text-danger">{errors.phone}</div>}
          </div>
          <div className="col-md-12 ">
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control text-white"
              placeholder="Your Email Address"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="form-control text-white"
              placeholder="Subject"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            />
            {errors.subject && <div className="text-danger">{errors.subject}</div>}
          </div>
          <div className="col-md-12">
            <textarea
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="form-control text-white"
              rows="6"
              placeholder="Write your Text"
              required
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            ></textarea>
            {errors.message && <div className="text-danger">{errors.message}</div>}
          </div>
          <div className="col-md-12">

            {!isPending && <button type="submit" className="btn btn-info">Submit</button>}
            {isPending && <button disabled className="btn btn-info">Sending ... </button>}

          </div>
        </div>
      </form>
    </>
  );
}
export default ContactForm;
