import React from 'react';
import '../../home.css';

const AddressBd = () => {
    return (
        <>
            <div className="info-box" style={{ backgroundColor: "var(--color-light-blue-opacity)" }}>
                <h3 className="text-white">Bangladesh Office: </h3>
                <p className="text-white" style={{ fontSize: "16px" }}>
                    <i className="bi bi-telephone text-white" style={{ fontSize: "16px", paddingRight:"15px" }}></i> 
                    +8801886780678
                </p>
                <p className="text-white" style={{ fontSize: "16px" }}>
                    <i className="bi bi-envelope text-white" style={{ fontSize: "16px", paddingRight:"15px" }}></i> 
                    info@websolutionfirm.com
                </p>
                <p className="text-white" style={{ fontSize: "16px" }}>
                    <i className="bi bi-geo-alt text-white" style={{ fontSize: "16px", paddingRight:"15px" }}></i> 
                    1283 (1st Floor), East Monipur, Begum Rokeya Sarani Road, <br />
                    <span style={{ paddingLeft:"5%" }}>Mirpur-10, Dhaka-1216.</span>
                </p>
            </div>
        </>
    );
};

export default AddressBd;