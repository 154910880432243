import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import api from "../../../api/api";

import "../../../home/home.css";
import './careerPage.css';
import "../../../App.css";

const ApplyForm = () => {
    const { slug } = useParams();

    const [state, setState] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [nid, setNid] = useState("");
    const [qualification, setQualification] = useState("");
    const [experience, setExperience] = useState("");
    const [current_salary, setCurrentSalary] = useState("");
    const [expected_salary, setExpectedSalary] = useState("");
    const [portfolio, setPortfolio] = useState("");
    const [github, setGithub] = useState("");
    const [figma, setFigma] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [note, setNote] = useState("");
    const [additional_notes, setAdditional_notes] = useState("");
    const [cv, setCv] = useState("");
    const [image, setPictures] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const cvRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        myFunction();
        return () => {
            setState({});
        };
    }, []);

    const myFunction = () => {
        api.get(`/career/${slug}/details`).then((res) => {
            if (res.status === 200) {
                setState(res.data.data);
            }
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset errors
        setErrors({});

        // Validation
        let newErrors = {};

        if (!name) {
            newErrors.name = "Full Name is required";
        }
        if (!email) {
            newErrors.email = "Email is required";
        }
        if (!phone) {
            newErrors.phone = "Phone is required";
        }
        if (!qualification) {
            newErrors.qualification = "Last Qualification is required";
        }
        if (!experience) {
            newErrors.experience = "Years of Experience is required";
        }
        if (state.job_type === 'employee') {
            if (!current_salary) {
                newErrors.current_salary = "Current Salary is required";
            } else if (isNaN(current_salary) || Number(current_salary) <= 0) {
                newErrors.current_salary = "Current Salary must be a positive number";
            }
            if (!expected_salary) {
                newErrors.expected_salary = "Expected Salary is required";
            } else if (isNaN(expected_salary) || Number(expected_salary) <= 0) {
                newErrors.expected_salary = "Expected Salary must be a positive number";
            }
        }
        if (!cv) {
            newErrors.cv = "CV/Resume is required";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return; // Stop form submission if there are errors
        }

        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("nid", nid);
        formData.append("qualification", qualification);
        formData.append("experience", experience);
        formData.append("current_salary", current_salary);
        formData.append("expected_salary", expected_salary);
        formData.append("portfolio", portfolio);
        formData.append("github", github);
        formData.append("figma", figma);
        formData.append("linkedin", linkedin);
        formData.append("note", note);
        formData.append("additional_notes", additional_notes);
        formData.append("cv", cv);
        formData.append("image", image);

        setIsPending(true);

        await api.post(`/career/${slug}/apply`, formData)
            .then(response => {
                if (response.status === 200) {
                    swal({
                        title: "Success!",
                        text: "Your application successfully received by Web Solution Firm. We will contact with you soon.",
                        icon: "success",
                        button: "OK!",
                        timer: 1500,
                    });

                    // Reset form fields
                    setName('');
                    setEmail('');
                    setPhone('');
                    setNid('');
                    setQualification('');
                    setExperience('');
                    setCurrentSalary('');
                    setExpectedSalary('');
                    setPortfolio('');
                    setGithub('');
                    setFigma('');
                    setLinkedin('');
                    setNote('');
                    setAdditional_notes('');
                    setCv('');
                    setPictures('');

                    if (cvRef.current) {
                        cvRef.current.value = '';
                    }
                    if (imageRef.current) {
                        imageRef.current.value = '';
                    }

                    navigate('');
                } else {
                    swal({
                        title: "Failed!",
                        text: "Please Check Required Fields.",
                        icon: "error",
                        button: "OK!",
                        timer: 1500,
                    });
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    setErrors(error.response.data.errors);
                } else {
                    console.log(error);
                }
            })
            
        setIsPending(false);
    }

    return (
        <div style={{ width: "80%" }}>
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="py-sm-4">
                <div className="banner">
                    <h1>Job Application Form</h1>
                </div>

                <div className="item">
                    <label className="col-form-label">Full Name <span className="danger">*</span></label>
                    <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="item">
                    <label className="col-form-label">Email <span className="danger">*</span></label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="item">
                    <label className="col-form-label">Phone <span className="danger">*</span></label>
                    <input
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control"
                    />
                    {errors.phone && <p className="error">{errors.phone}</p>}
                </div>
                <div className="item">
                    <label className="col-form-label">Last Qualification <span className="danger">*</span></label>
                    <input
                        type="text"
                        name="qualification"
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                        className="form-control"
                    />
                    {errors.qualification && <p className="error">{errors.qualification}</p>}
                </div>
                <div className="item">
                    <label className="col-form-label">Years of Experience <span className="danger">*</span></label>
                    <input
                        type="text"
                        name="experience"
                        value={experience}
                        onChange={(e) => setExperience(e.target.value)}
                        className="form-control"
                    />
                    {errors.experience && <p className="error">{errors.experience}</p>}
                </div>

                {state.job_type === 'employee' && 
                    <div className="item">
                        <label className="col-form-label">Salary <span className="danger">*</span></label>
                        <div className="row">
                            <div className="col-md-6">
                                <input
                                    type="number"
                                    name="current_salary"
                                    value={current_salary}
                                    placeholder="Current Salary" 
                                    onChange={(e) => setCurrentSalary(e.target.value)}
                                    className="form-control"
                                />
                                {errors.current_salary && <p className="error">{errors.current_salary}</p>}
                            </div>

                            <div className="col-md-6">
                                <input
                                    type="number"
                                    name="expected_salary"
                                    value={expected_salary}
                                    placeholder="Expected Salary"
                                    onChange={(e) => setExpectedSalary(e.target.value)}
                                    className="form-control"
                                />
                                {errors.expected_salary && <p className="error">{errors.expected_salary}</p>}
                            </div>
                        </div>
                    </div>
                }

                <div className="item">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="col-form-label">Portfolio</label>
                            <input
                                type="text"
                                name="portfolio"
                                value={portfolio}
                                onChange={(e) => setPortfolio(e.target.value)}
                                className="form-control"
                            />
                            {errors.portfolio && <p className="error">{errors.portfolio}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">GitHub</label>
                            <input
                                type="text"
                                name="github"
                                value={github}
                                onChange={(e) => setGithub(e.target.value)}
                                className="form-control"
                            />
                            {errors.github && <p className="error">{errors.github}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Figma</label>
                            <input
                                type="text"
                                name="figma"
                                value={figma}
                                onChange={(e) => setFigma(e.target.value)}
                                className="form-control"
                            />
                            {errors.figma && <p className="error">{errors.figma}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">LinkedIn</label>
                            <input
                                type="text"
                                name="linkedin"
                                value={linkedin}
                                onChange={(e) => setLinkedin(e.target.value)}
                                className="form-control"
                            />
                            {errors.linkedin && <p className="error">{errors.linkedin}</p>}
                        </div>
                    </div>
                </div>
                <div className="item">
                    <label className="col-form-label">Note</label>
                    <textarea
                        name="note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="item">
                    <label className="col-form-label">Additional Notes</label>
                    <textarea
                        name="additional_notes"
                        value={additional_notes}
                        onChange={(e) => setAdditional_notes(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="item">
                    <label className="col-form-label">CV/Resume <span className="danger">*</span> <small className="text-danger">(File must be a pdf file && max file size: 500kb)</small> </label>
                    <input
                        type="file"
                        name="cv"
                        ref={cvRef}
                        onChange={(e) => setCv(e.target.files[0])}
                        className="form-control"
                    />
                    {errors.cv && <p className="error">{errors.cv}</p>}
                </div>
                <div className="item">
                    <label className="col-form-label">Image <small className="text-danger">(Image must be jpeg,jpg,png,gif,webp && max file size: 200kb)</small></label>
                    <input
                        type="file"
                        name="image"
                        ref={imageRef}
                        onChange={(e) => setPictures(e.target.files[0])}
                        className="form-control"
                    />
                    {errors.image && <p className="error">{errors.image}</p>}
                </div>
                
                <div className="btn-block pt-4 pb-2">
                    {!isPending && <button type="submit">Send Application</button>}
                    {isPending && <button disabled className="btn btn-info">Send Application to WSF ... </button>}
                </div>

            </form>
        </div>
    );
}

export default ApplyForm;
