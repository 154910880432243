import React from 'react';
import LandingPage from '../home/LandingPage';
import Video from './Video';

const Home = ({ setProgress }) => {
      
    return (
        <>
          <div className='video'>
            <Video />
          </div>            

          <main className="main">
            <LandingPage setProgress = {setProgress}/>
          </main>
          
        </>
    );
};

export default Home;