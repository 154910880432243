import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import "../../../../home/home.css";
import BlogCard from '../BlogCard';
import BlogSidebar from './CategorySidebar';
import BlogNotFoundCard from '../BlogNotFoundCard';
import api from '../../../../api/api';

function Layout({ setProgress }) {
    const { categorySlug } = useParams();
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        setProgress(10);

        api.get(`/blogs/${'knowledge_based'}/sub-categories/${categorySlug}`).then((res) => {
            const fetchedCategories = res.data.data.categories;

            if (res.data.data.blogs.length > 0) {
                setBlogs(res.data.data.blogs);
            } else {
                api.get(`/blogs/${'knowledge_based'}/blog-page`).then((res) => {
                    const allBlogs = res.data.data.blogs;

                    const filteredBlogs = allBlogs.filter((blog) =>
                        fetchedCategories.some((category) =>
                            category.blogs.some((categoryBlog) => categoryBlog.id === blog.id)
                        )
                    );

                    setBlogs(filteredBlogs);
                }).catch(err => {
                    console.log(err);
                });
            }

            setProgress(100);
        }).catch(err => {
            console.log(err);
            setProgress(100);
        });

    }, [categorySlug]);

    return (
        <>
            <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
                <div
                    className="container"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="0.9s"
                >
                    <p className="text-white text-center">
                        <span className="title">Knowledgebase</span>
                        <span className='text-center subTitle'>
                            At Tech Innovators Knowledgebase, we dive into the latest in technology
                        </span>
                    </p>
                </div>
            </section>

            <main className='main'>
                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">

                            <div className="col-lg-4">
                                <BlogSidebar categorySlug={categorySlug} />
                            </div>

                            <div className="col-lg-8 entries">
                                <div className='row'>
                                    <div className="col-12 col-md-12">
                                        <article className="entry">
                                            {
                                                blogs.length > 0 ? blogs.map(blog => (
                                                    <BlogCard blog={blog} key={blog.id} />
                                                )) : <BlogNotFoundCard />
                                            }
                                        </article>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}

export default Layout