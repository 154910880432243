import React from "react";

function BlogNotFoundCard() {

  return (
    <>
      <div className="col-12 col-md-12">
        <article className="entry ">
          <div className="entry-meta">
            <p className="mb-0 text-secondary text-center">Data not found.</p>
          </div>
        </article>
      </div>
    </>
  );
}

export default BlogNotFoundCard;
