import React from 'react';
import '../home.css';
import CountUp from 'react-countup';

const Achivement = () => {
    return (
        <>
            <div >
                <div className="col-lg-12 text-center">
                    <header className="section-header">
                        <p className="text-white white-un">Our Achievement & Success</p>
                    </header>
                </div>
                <div className="container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
                
                    <div className="row gy-4">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="count-box rotates counterBox">
                                <i className="bi bi-laptop"></i>
                                <div>
                                    <CountUp className="purecounter" end={17} duration={3} />
                                    <p>Web Application</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="count-box rotates counterBox">
                                <i className="bi bi-phone" style={{ color: "#15be56" }}></i>
                                <div>
                                    <CountUp className="purecounter" end={9} duration={3} />
                                    <p>Mobile Application</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="count-box rotates counterBox">
                                <i className="bi bi-megaphone" style={{ color: "#bb0852" }}></i>
                                <div>
                                    <CountUp className="purecounter" end={14} duration={3} />
                                    <p>Digital Marketing</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="count-box rotates counterBox">
                                <i className="bi bi-code-square" style={{ color: "#ee6c20" }}></i>
                                <div>
                                    <CountUp className="purecounter" end={16} duration={3} />
                                    <p>Software Solution</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Achivement;