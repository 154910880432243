import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";
import useActiveLink from '../../hooks/useActiveLink';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}


const SmallMediumBusiness = () => {
    const [activeLink, sections] = useActiveLink({
        shopM: null,
        inventoryM: null,
        assetM: null,
        tradeB: null,
        crm: null,
    });

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Small to Medium Business</span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#shopM' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#shopM" ? "activeTab" : ""}
                                        >
                                            Shop Management
                                        </Link>
                                        <Link smooth to='#inventoryM' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#inventoryM" ? "activeTab" : ""}
                                        >
                                            Inventory Management
                                        </Link>
                                        <Link smooth to='#assetM' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#assetM" ? "activeTab" : ""}
                                        >
                                            Asset Management
                                        </Link>
                                        <Link smooth to='#tradeB' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#tradeB" ? "activeTab" : ""}
                                        >
                                            Trade Business Solution
                                        </Link>
                                        <Link smooth to='#crm' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#crm" ? "activeTab" : ""}
                                        >
                                            CRM
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Design Start */}
                        <div id='shopM' 
                            className="row gx-0"
                            ref={(el) => (sections.current.shopM = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Shop Management</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Our developed Shop Management System / Store management system is a cloud-based web application that keeps track of all the inventories, products, sales, purchase, Accounts, bill, invoice related every event happening in your day-to-day business. Admin will be able to create invoices and run random / schedule reports anytime. System has some great dashboard where you will get your business inside quickly and there are some even alerts to keep you automatically updated.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        <b>Modules of the Shop management System</b>
                                    </p>
                                    <p style={{ marginTop:'-20px' }}> 
                                        <b>#</b> Product Module  
                                        <b className='pl-10px'> #</b> Stock Module
                                        <b className='pl-10px'> #</b> Discounts Module 
                                        <b className='pl-10px'> #</b> Inventory Module 
                                        <b className='pl-10px'> #</b> Sales & Purchase Module 
                                        <b className='pl-10px'> #</b> Accounts Module 
                                        <b className='pl-10px'> #</b> User Module 
                                        <b className='pl-10px'> #</b> Advanced Analytics 
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* Graphics Design Start */}
                        <div id='inventoryM' 
                            className="row gx-0"
                            ref={(el) => (sections.current.inventoryM = el)}
                        >  
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Graphics Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Graphics Design End */}

                        {/* Logo Design Start */}
                        <div id='assetM' 
                            className="row gx-0"
                            ref={(el) => (sections.current.assetM = el)}
                        >                         
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Logo Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Logo Design End */}

                        {/* Brand Identity Start */}
                        <div id='tradeB' 
                            className="row gx-0"
                            ref={(el) => (sections.current.tradeB = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Brand Identity</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Brand Identity End */}

                        {/* Animation Design Start */}
                        <div id='crm' 
                            className="row gx-0"
                            ref={(el) => (sections.current.crm = el)}
                        >       
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Animation Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Animation Design End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default SmallMediumBusiness;