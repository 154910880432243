import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";
import useActiveLink from '../../hooks/useActiveLink';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const DigitalMarketing = () => {
    const [activeLink, sections] = useActiveLink({
        digitalMarketing: null,
        seo: null,
        googleAnalytics: null,
        socialMediaAdCampaign: null,
        adCampaign: null,
    });

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Digital Marketing & SEO</span>
                        <span className='text-center subTitle'>
                            We use Data analytics & Data driven insight marketing.
                        </span>
                    </p>
                </div>
            </section>

            <main className="main">

                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop: '-50px' }}>
                    <div style={{ backgroundColor: '#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#digitalMarketing'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#digitalMarketing" ? "activeTab" : ""}
                                        >
                                            Digital Marketing
                                        </Link>

                                        <Link smooth to='#seo'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#seo" ? "activeTab" : ""}
                                        >
                                            SEO
                                        </Link>

                                        <Link smooth to='#googleAnalytics'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#googleAnalytics" ? "activeTab" : ""}
                                        >
                                            Google Analytics
                                        </Link>

                                        <Link smooth to='#socialMediaAdCampaign'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#socialMediaAdCampaign" ? "activeTab" : ""}
                                        >
                                            Social Media
                                        </Link>

                                        <Link smooth to='#adCampaign'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#adCampaign" ? "activeTab" : ""}
                                        >
                                            Ad Campaign
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Design Start */}
                        <div id='digitalMarketing'
                            className="row gx-0"
                            ref={(el) => (sections.current.digitalMarketing = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Digital Marketing </h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We create digital campaigns that target your audience specifically, driving leads and sales and helping you grow. Our Digital marketing campaigns also usually involve integrating multiple digital channel strategies (multi-channel) such as SEO, content marketing, display advertising, and email marketing.
                                    </p>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Digital Marketing advancements have made it fairly easy to measure the efficacy of marketing. We can do marketing of your products or services using digital technologies via the internet platform. We also accommodate different Digital marketing strategies such as Social Media Marketing, Search Engine Optimisation, and Email Marketing
                                    </p>
                                    <p style={{ marginTop: '-20px' }}>
                                        # Search Engine Optimisation (SEO) <br />
                                        # Search Engine Marketing (SEM) <br />
                                        # Social Media Marketing (SMM) <br />
                                        # Pay-Per-Click (PPC). <br />
                                        # Email Marketing <br />
                                        # Marketing Automation <br />
                                        # Digital Advertising <br />
                                        # Content Marketing <br />
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/services/dm&seo/web_solution_firm_digital-marketing.webp" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* SEO Start */}
                        <div id='seo'
                            className="row gx-0"
                            ref={(el) => (sections.current.seo = el)}
                        >
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/dm&seo/web_solution_firm_seo.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>SEO</h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Organic Search, in order to rank a website on top of the organic search engine results pages (SERPs), We implement a digital marketing strategy known as search engine optimisation (SEO). We do both Off page and on page SEO.
                                    </p>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Paid Search, the paid search ads results appear all the way up at the top of the Organic Search / SERPs. We help you set up everything including keywords and analytics. This strategy is also known as search engine marketing (SEM) / Pay-Per-Click (PPC).
                                    </p>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Our package includes but not limited to
                                    </p>
                                    <p style={{ marginTop: '-20px' }}>
                                        # On-page & off-page SEO <br />
                                        # Finding right Keywords  <br />
                                        # Ensuring meta tags, title tags and headers contain keywords. <br />
                                        # Ensuring the website loads quickly. <br />
                                        # Eliminating duplicate content. <br />
                                        # Cleaning up the backend coding. <br />
                                        # Making sure all images are optimised and have “alt” tags. <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* SEO End */}

                        {/* Google Analytice Start */}
                        <div id='googleAnalytics'
                            className="row gx-0"
                            ref={(el) => (sections.current.googleAnalytics = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Google Analytics</h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Google Analytics is a web analytics service that tracks and reports your website traffic. It helps to understand your customers so you can deliver better experiences. We accommodate other Analytics software as well. preference.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/dm&seo/web_solution_firm_google-analitics.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Google Analytice End */}

                        {/* Social Media Ad Campaign Start */}
                        <div id='socialMediaAdCampaign'
                            className="row gx-0"
                            ref={(el) => (sections.current.socialMediaAdCampaign = el)}
                        >
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/dm&seo/web_solution_firm_social-media.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Social Media</h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Social media needs no introduction. People use it daily to keep up to date with news or connect to friends and family across the globe.
                                    </p>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Businesses are on social media to connect to potential customers by engaging them in a more personal and authentic way. With the help of various social media marketing tools, you will be able to understand the needs and interests of your target audience across various social media platforms.
                                    </p>
                                    <p style={{ marginTop: '-20px' }}>
                                        # Facebook <br />
                                        # LinkedIn <br />
                                        # Instagram <br />
                                        # Google Plus <br />
                                        # YouTube <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Social Media Ad Campaign End */}

                        {/* adCampaign Start */}
                        <div id='adCampaign'
                            className="row gx-0"
                            ref={(el) => (sections.current.adCampaign = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Ad Campaign</h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Many entrepreneurs think launching an advertising campaign means simply creating an ad. However, they are mistaken. It’s crucial to set up right from the start to achieve good ROI.     If you’re considering launching an advertising campaign, we recommend you see a specialist.
                                    </p>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We are specialists in launching Ad campaigns in Facebook, LinkedIn, Instagram, Google Plus, YouTube. Ad campaigns are an important marketing strategy for all kinds of businesses that want to increase brand awareness and profits.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/dm&seo/web_solution_firm_campaign.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* adCampaign End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default DigitalMarketing;