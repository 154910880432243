import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";
import useActiveLink from '../../hooks/useActiveLink';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const MobileApplication = () => {
    const [activeLink, sections] = useActiveLink({
        androidApp: null,
        iosApp: null,
        hybridCross: null,
    });

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Mobile Application</span>
                        <span className='text-center subTitle'>
                            We build Powerful, scalable, stable app (iOS & Android)
                        </span>
                    </p>
                </div>
            </section>

            <main className="main">

                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop: '-50px' }}>
                    <div style={{ backgroundColor: '#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#androidApp'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#androidApp" ? "activeTab" : ""}
                                        >
                                            Android Application
                                        </Link>

                                        <Link smooth to='#iosApp'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#iosApp" ? "activeTab" : ""}
                                        >
                                            iOS Application
                                        </Link>

                                        <Link smooth to='#hybridCross'
                                            scroll={el => scrollWithOffset(el)}
                                            className={activeLink === "#hybridCross" ? "activeTab" : ""}
                                        >
                                            Hybrid/Cross Platform
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Design Start */}
                        <div id='androidApp'
                            className="row gx-0"
                            ref={(el) => (sections.current.androidApp = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Android Application</h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Android is one of the most popular ecosystems. There are several devices that operate in it. Our team of Android app designers holds expertise in creating memorable experiences across all Android devices and versions.
                                    </p>
                                    <p style={{ marginTop: '-20px' }}>
                                        # Android App Development Consultation <br />
                                        # Android UI/UX Design <br />
                                        # Custom Android Application Development  <br />
                                        # Android Software Testing <br />
                                        # Multi-platform Deployment <br />
                                        # Google Play Store Submission <br />
                                        # Post-launch support & Maintenance   <br />
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/services/mobile/web_solution_firm_mobile_apps.webp" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* Application 1 Start */}
                        <div id='iosApp'
                            className="row gx-0"
                            ref={(el) => (sections.current.iosApp = el)}
                        >
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/mobile/web_solution_firm_ios_app.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>iOS Application</h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        iOS application development is known as the best choice for building secure, scalable digital solutions. Our iOS software developers ensure that your applications are engineered to make you future-forward businesses. Develop next-gen iOS apps that are quality tested for perfection with future-forward iOS application development services.
                                    </p>
                                    <p style={{ marginTop: '-20px' }}>
                                        # iOS App Development Consultation <br />
                                        # iOS UI/UX Design <br />
                                        # Custom iOS Application Development  <br />
                                        # iOS Software Testing <br />
                                        # Multi-platform Deployment <br />
                                        # App Store Submission <br />
                                        # Post-launch support & Maintenance  <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Application 1 End */}

                        {/* Application 2 Start */}
                        <div id='hybridCross'
                            className="row gx-0"
                            ref={(el) => (sections.current.hybridCross = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Hybrid/Cross Platform</h2>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        If you have tight budget / and further development & easy maintenance plan than Hybrid/ cross-platform for you. With React Native, We will build your iOS and Android mobile app. React Native got the same design as React, letting developer compose a rich mobile UI from declarative components.
                                    </p>
                                    <p>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Using this framework, our team has been building a real mobile app that's indistinguishable from an app built using Objective-C or Java. React Native uses the same fundamental UI building blocks as regular iOS and Android apps.
                                    </p>
                                    <p style={{ marginTop: '-20px' }}>
                                        # Hybrid/ cross App Development Consultation <br />
                                        # App UI/UX Design <br />
                                        # Hybrid/ cross Application Development <br />
                                        # App Testing <br />
                                        # Multi-platform Deployment <br />
                                        # Google play & App Store Submission <br />
                                        # Post-launch support & Maintenance  <br />
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/mobile/web_solution_firm_hybrid.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Application 2 End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default MobileApplication;