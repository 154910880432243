import React, {useState} from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import SolutionItem from './SolutionItem';

import '../home.css';
import '../headerMenu.css';
import { Link, NavLink } from 'react-router-dom';

const Solution = ({ handleLinkClick }) => {

    const[items, setItems] = useState(
        SolutionItem.filter((currentElement) => {
            return currentElement.cat === '';
        })
    );

    const [activeItem, setActiveItem] = useState('none');

    const filterItem = (categoryItem) => {
        const updateItems = SolutionItem.filter((currentElement) => {
            return currentElement.cat === categoryItem;
        });
        setItems(updateItems);

        setActiveItem(categoryItem); // Set the active item
    }

    const handleMouseEnter = (categoryItem) => {
        filterItem(categoryItem);
    };

    // const handleMouseLeave = () => {
    //     setItems(SolutionItem.filter((currentElement) => currentElement.cat === ''));
    //     setActiveItem('none');
    // };

    return (
        <>
            <div className="col-lg-3 solutionMenu">
                {/* <a className={activeItem === 'ecommerce-solution' ? 'active' : ''} style={{ marginBottom:'5px' }} onClick={() => filterItem('ecommerce')} >eCommerce Solution <IoIosArrowForward /></a>
                <a className={activeItem === 'edtech' ? 'active' : ''} style={{ marginBottom:'5px' }} onClick={() => filterItem('edtech')}>EdTech Solution <IoIosArrowForward /></a>
                <a className={activeItem === 'small-to-medium-business' ? 'active' : ''} style={{ marginBottom:'5px' }} onClick={() => filterItem('smallbusiness')}>Small to Medium Business <IoIosArrowForward /></a>
                <a className={activeItem === 'corporate' ? 'active' : ''} style={{ marginBottom:'5px' }} onClick={() => filterItem('corporate')}>Corporate Solution <IoIosArrowForward /></a>
                <a className={activeItem === 'consultancy' ? 'active' : ''} style={{ marginBottom:'5px' }} onClick={() => filterItem('consultancy')}>Consultancy <IoIosArrowForward /></a> */}
                
                <NavLink 
                    to='ecommerce-solution' 
                    className={activeItem === 'ecommerce' ? 'active' : ''} 
                    style={{ marginBottom:'5px' }}
                    onMouseEnter={() => handleMouseEnter('ecommerce')}
                    // onMouseLeave={handleMouseLeave}
                    onClick={() => {
                        filterItem('ecommerce');
                        handleLinkClick('ecommerce-solution');
                    }}
                >
                    eCommerce Solution <IoIosArrowForward />
                </NavLink>
                <NavLink 
                    to='edtech' 
                    className={activeItem === 'edtech' ? 'active' : ''} 
                    style={{ marginBottom:'5px' }} 
                    onMouseEnter={() => handleMouseEnter('edtech')}
                    onClick={() => {
                        filterItem('edtech');
                        handleLinkClick('edtech');
                    }}
                >
                    EdTech Solution <IoIosArrowForward />
                </NavLink>
                <NavLink 
                    to='small-to-medium-business' 
                    className={activeItem === 'smallbusiness' ? 'active' : ''} 
                    style={{ marginBottom:'5px' }} 
                    onMouseEnter={() => handleMouseEnter('smallbusiness')}
                    onClick={() => {
                        filterItem('smallbusiness');
                        handleLinkClick('small-to-medium-business');
                    }}
                >
                    Small to Medium Business <IoIosArrowForward />
                </NavLink>
                <NavLink 
                    to='corporate' 
                    className={activeItem === 'corporate' ? 'active' : ''} 
                    style={{ marginBottom:'5px' }} 
                    onMouseEnter={() => handleMouseEnter('corporate')}
                    onClick={() => {
                        filterItem('corporate');
                        handleLinkClick('corporate');
                    }}
                >
                    Corporate Solution <IoIosArrowForward />
                </NavLink>
                <NavLink 
                    to='consultancy' 
                    className={activeItem === 'consultancy' ? 'active' : ''} 
                    style={{ marginBottom:'5px' }} 
                    onMouseEnter={() => handleMouseEnter('consultancy')}
                    onClick={() => {
                        filterItem('consultancy')
                        handleLinkClick('consultancy');
                    }}
                >
                    Consultancy <IoIosArrowForward />
                </NavLink>
            </div>
            
            <div className="col-lg-9">
                <div className="row solution-cards">
                    {
                        items.map((elem) => {
                            const {id, title, desc, image, url} = elem;
                            return (
                                <div className="col-lg-3 solution-cards-body" key={id}>
                                    <div className="img">
                                        <Link to = {url} onClick={() => handleLinkClick(url)}><img src={image} alt="" /></Link>
                                    </div> 
                                    <h5>{title}</h5>
                                    <p>{desc}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default Solution;