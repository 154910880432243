import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import api from "../../../api/api";

import "../../../home/home.css";
import { formatString } from "../../../utils/utils";
import { formatDate } from "../../../utils/utils";

const JobDetailsContent = () => {
  const { slug } = useParams();

  const [state, setState] = useState({});

  useEffect(() => {
    myFunction();
    return () => {
      setState({});
    };
  }, []);

  const myFunction = () => {
    api.get(`/career/${slug}/details`).then((res) => {
      if (res.status === 200) {
        setState(res.data.data);
      }
    });
  }

  return (
    <>
      <div className="col-lg-12 col-md-12" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="0.9s">
        <div className="cardBox" id="cardBox" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-md-12" style={{ padding: "15px 50px" }}>
              <h2 className="mt-4 mb-4">
                <b>{state.job_title}</b>
              <hr />
              </h2>
              <div className="jobSummary" style={{ overflowX: "auto" }}>
                <table>
                  <tbody>
                    <tr>
                      <td><strong>Published on:</strong> {formatDate(state.published_date)}</td>
                      <td><strong>Vacancy:</strong> {state.vacancy}</td>
                      <td><strong>Employment Status:</strong> {state.employment_status}</td>
                      <td><strong>Salary:</strong> {state.salary ? state.salary : 'Negotiable'}</td>
                    </tr>
                    <tr>
                      <td><strong>Application Deadline:</strong> {formatDate(state.application_deadline)}</td>
                      <td><strong>Experience:</strong> {state.year_of_experience ? state.year_of_experience : 'N/A'}</td>
                      <td><strong>Job Location:</strong> {state.office_location}</td>
                      <td><strong>Work Place:</strong> {formatString(state.work_place)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-md-12 col-12">
              <ul>
                <li style={{ paddingBottom: "0px" }}>
                  <span style={{ paddingRight: "10px" }}></span>
                  <div
                    dangerouslySetInnerHTML={{ __html: state.descriptions }}
                    style={{ width: "100%" }}
                  ></div>
                </li>
              </ul>
            </div>

            <div
              className="applyNow col-md-12"
              style={{ padding: "15px 50px 50px 50px" }}
            >
              <div className="text-center text-rg-start">
                <Link
                  to={`/company/career/apply-job/${state.slug}`}
                  className="btn-apply-now d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Apply Now</span>
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetailsContent;
