import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../home/home.css';

import ContactForm from '../../home/landingPageContent/contactUs/ContactForm';
import AddressItem from './AddressItem';

const ContactUsPage = () => {
    const [items, setItems] = useState(
        AddressItem.filter((currentElement) => {
            return currentElement.cat === 'nz';
        })
    );

    const [activeCategory, setActiveCategory] = useState('nz');

    const filterItem = (categItem) => {
        const updateItems = AddressItem.filter((currentElement) => {
            return currentElement.cat === categItem;
        });

        setItems(updateItems);
        setActiveCategory(categItem);
    }

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        {/* <span className='title'>Contact Us Page</span> */}
                        <span className='sub-title text-center addressTitle'>DO YOU HAVE A GREAT IDEA? WE CAN MAKE IT A REALITY</span>
                    </p>
                </div>
            </section>
            <main className="main">

                {/* ======= Portfolio Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop: '-50px' }}>
                    <div style={{ backgroundColor: '#090046', }}>
                        <div className="container" >
                            <div className="row" >
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link to='' 
                                            onClick={() => filterItem('nz')} 
                                            className={activeCategory === 'nz' ? 'activeTab' : ''}
                                        >
                                            New Zealand (HQ)
                                        </Link>

                                        <Link to='' 
                                            onClick={() => filterItem('bd')} 
                                            className={activeCategory === 'bd' ? 'activeTab' : ''}
                                        >
                                            Bangladesh
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contact" className="contact" style={{ marginTop: '-50px' }}>
                    {
                        items.map((elem) => {
                            const { id, heading } = elem;
                            return (

                                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s" key={id}>
                                    <header className="section-header">
                                        <p className="" style={{ float: "left", paddingBottom: "20px", color: 'red' }}>
                                            {heading}
                                        </p>
                                    </header>
                                </div>
                            )
                        })
                    }
                </section>
                {/* ======= Contact Section =======  */}

                {
                    items.map((elem) => {
                        const { id, title, phone, email, address, address2 } = elem;

                        return (
                            <section id="contact" className="contact"

                                key={id}
                            >
                                <div className="container" data-aos="fade-up"
                                    data-aos-duration="500" data-aos-delay="0.9s"
                                >
                                    <div className="row gy-4">
                                        <div className="col-lg-6">
                                            <ContactForm />
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="row gy-4">
                                                <header className="contact-address-header">
                                                    <p className="text-logo-blue">Our Address</p>
                                                    {/* <h2 className="text-logo-blue" style={{ paddingTop: "10px" }}>
                                                        You are always welcome to our office
                                                    </h2> */}
                                                </header>
                                                <div className="col-md-12">

                                                    <div className="info-box"
                                                        style={{ backgroundColor: "var(--color-light-blue-opacity)" }}
                                                    >
                                                        <h3 className="text-white">{title} </h3>
                                                        <p className="text-white" style={{ fontSize: "16px" }}>
                                                            <i className="bi bi-telephone text-white"
                                                                style={{ fontSize: "16px", paddingRight: "15px" }}
                                                            ></i>
                                                            {phone}
                                                        </p>

                                                        {/* { elem.id == 2 && 
                                                        <p className="text-white" style={{ fontSize: "16px" }}>
                                                            <i className="bi bi-telephone text-white" 
                                                                style={{ fontSize: "16px", paddingRight:"15px" }}
                                                            ></i> 
                                                            {phone2}
                                                        </p>
                                                        } */}

                                                        <p className="text-white" style={{ fontSize: "16px" }}>
                                                            <i className="bi bi-envelope text-white"
                                                                style={{ fontSize: "16px", paddingRight: "15px" }}
                                                            ></i>
                                                            {email}
                                                        </p>
                                                        <p className="text-white" style={{ fontSize: "16px" }}>
                                                            <i className="bi bi-geo-alt text-white"
                                                                style={{ fontSize: "16px", paddingRight: "15px" }}
                                                            ></i>
                                                            {address} <br />
                                                            <span style={{ paddingLeft: "5%" }}>{address2}</span>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                }

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default ContactUsPage;