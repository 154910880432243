export const formatString = (str) => {
  if (typeof str !== 'string') {
    return '';
  }

  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const formatDate = (dateString) => {
  if (!dateString) return '';

  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};


// utils.js
export const getActiveMenu = (path) => {
  const solutionPaths = [
    '/ecommerce-solution', 
    '/edtech', 
    '/small-to-medium-business', 
    '/corporate', 
    '/consultancy'
  ];
  const servicePaths = [
    '/web-application-details', 
    '/software-development-details', 
    '/mobile-application-details', 
    '/creative-details', 
    '/digital-marketing-and-seo-details', 
    '/domain-and-hosting-details'
  ];
  const companyPaths = [
    '/our-clients', 
    '/our-technology-partners', 
    '/about-us-pages', 
    '/career', 
    '/testimonials', 
    '/faq', 
    '/our-teams'
  ];
  const helpCenterPaths = [
    '/knowledge-base', 
    '/contact-us'
  ];

  if (solutionPaths.includes(path)) {
    return '/solution';
  } else if (servicePaths.includes(path)) {
    return '/web-application-details';
  } else if (companyPaths.includes(path)) {
    return '/about-us';
  } else if (helpCenterPaths.includes(path)) {
    return '/help-center';
  } else {
    return '';
  }
};
