import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";
import useActiveLink from '../../hooks/useActiveLink';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const ConsultancySolution = () => {
    const [activeLink, sections] = useActiveLink({
        itConsultancy: null,
        softwareConsultancy: null,
        dataConsultancy: null,
    });

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Consultancy</span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#itConsultancy' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#itConsultancy" ? "activeTab" : ""}
                                        >
                                            IT Consultency
                                        </Link>
                                        <Link smooth to='#softwareConsultancy' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#softwareConsultancy" ? "activeTab" : ""}
                                        >
                                            Software Consultancy
                                        </Link>
                                        <Link smooth to='#dataConsultancy' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#dataConsultancy" ? "activeTab" : ""}
                                        >
                                            Data & Analytics Consultancy
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Design Start */}
                        <div id='itConsultancy' 
                            className="row gx-0"
                            ref={(el) => (sections.current.itConsultancy = el)}
                        >
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Web Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* Graphics Design Start */}
                        <div id='softwareConsultancy' 
                            className="row gx-0"
                            ref={(el) => (sections.current.softwareConsultancy = el)}
                        >  
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Graphics Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Graphics Design End */}

                        {/* Logo Design Start */}
                        <div id='dataConsultancy' 
                            className="row gx-0"
                            ref={(el) => (sections.current.dataConsultancy = el)}
                        >                         
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Logo Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Quisquam vel ut sint cum eos hic dolores aperiam. Sed deserunt et. Inventore et et dolor consequatur itaque ut voluptate sed et. Magnam nam ipsum tenetur suscipit voluptatum nam et est corrupti.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Logo Design End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default ConsultancySolution;