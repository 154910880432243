import React from "react";
import { Link } from "react-router-dom";
import config from "../../../config";

function BlogCard({ blog }) {

  return (
    <>
      <div className="col-12 col-md-6">
        <article className="entry mb-4">
          <div className="entry-img">
            <Link to={`/company/blogs/details/${blog.slug}`}>
              <img
                src={`${config.IMAGE_BASE_PATH}${blog ? blog.featured_image : ''}`}
                alt={blog && blog.title}
                className="w-100 h-100"
              />
            </Link>
          </div>
          <h2 className="entry-title">
            <Link to={`/company/blogs/details/${blog.slug}`}>{blog.title.length > 30 ? blog.title.slice(0, 29) + '...' : blog.title}</Link>
          </h2>
          <div className="entry-meta">
            <ul className="d-flex justify-content-between align-items-center">
              <li className="d-flex align-items-center">
                <i className="bi bi-person"></i>
                <span className="blog-author-read-time-wrapper">
                  {blog.created_by.full_name}
                </span>
              </li>
              <li className="d-flex align-items-center">
                <i className="bi bi-clock"></i>{" "}
                <span className="blog-author-read-time-wrapper">
                  <time>{blog.created_at}</time>
                </span>
              </li>
            </ul>
            <p className="mt-4 mb-0 text-secondary">{blog.short_description ? blog.short_description.length > 100 ? blog.short_description.slice(0, 50) + '...' : blog.short_description : 'N/A'}</p>
          </div>
        </article>
      </div>
    </>
  );
}

export default BlogCard;
