import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { AiOutlineSlack } from "react-icons/ai";
import useActiveLink from '../../hooks/useActiveLink';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const CreativePage = () => {
    const [activeLink, sections] = useActiveLink({
        uxUI: null,
        webDesign: null,
        graphicsDesign: null,
        logoDesign: null,
        brandIdentity: null,
    });

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Creative</span> 
                        <span className='text-center subTitle'>
                            Every visual task is an opportunity to engage with audiences
                        </span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#uxUI' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#uxUI" ? "activeTab" : ""}
                                        >
                                            UX & UI Design
                                        </Link>

                                        <Link smooth to='#webDesign' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#webDesign" ? "activeTab" : ""}
                                        >
                                            Web Design
                                        </Link>

                                        <Link smooth to='#graphicsDesign' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#graphicsDesign" ? "activeTab" : ""}
                                        >
                                            Graphics Design
                                        </Link>

                                        <Link smooth to='#logoDesign' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#logoDesign" ? "activeTab" : ""}
                                        >
                                            Logo Design
                                        </Link>

                                        <Link smooth to='#brandIdentity' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#brandIdentity" ? "activeTab" : ""}
                                        >
                                            Brand Identity
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                        
                        {/* Photo & Video Editing Start */}
                        <div id='uxUI' 
                            className="row gx-0"
                            ref={(el) => (sections.current.uxUI = el)}
                        >
                            {/* Our Values Start */}                            
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>UX & UI Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        No matter how powerful or innovative your product idea is, without a good user interface design, it won’t achieve success easily. In today’s competitive world, only a good user experience will define the future of the app.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        An easy-to-navigate user experience, fast-performing, content-rich and best functionality app/ website is a priority for any businesses. You have big plans for your dream project, and we want to know all about them. Together, we’ll explore your brand from the inside out, asking the important questions, doing research
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        When you partner with us, you’ll get the full extent of our UX/UI Design process that includes detailed research, wireframing, rapid prototyping, testing, and refinement. When you combine this with our dedication to continuous learning, you get an unmatched service that grows right alongside your company, ensuring that you remain a leader in your industry
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Our team thrives on solving interesting problems with fresh solutions
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/creative/web_solution_firm_ui-ux-design.webp" className="img-fluid" alt="" />
                            </div>
                            {/* Our Values End */}
                        </div>
                        {/* Photo & Video Editing End */}

                        {/* Web Design Start */}
                        <div id='webDesign' 
                            className="row gx-0"
                            ref={(el) => (sections.current.webDesign = el)}
                        >
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/services/creative/web_solution_firm_web-design.webp" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Web Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Having a strong digital presence no longer is optional – it’s required if you want to be found online by potential customers. We are passionate about creating exceptional websites. Here at Web Solution Firm No templates are used; all designs created from scratch to make your business shine in the crowd.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        A professionally designed business website
                                    </p>
                                    <p style={{ marginTop:'-20px' }}> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Custom designed websites to suit your brand and business needs <br/>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We will take care of responsive mobile-friendly websites with rich UI. <br/>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We'll be there for any updates and changes you need to your website. <br/>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We provide access to your website backend and files <br/>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        The latest technologies used to provide the best possible websites <br/>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We design and build in-house so there is no middleman cost  <br/>
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Tools and Technology we will be using according to your plan and budget (CMS, HTML, CSS, XML, JavaScript, jQuery, react JS, PHP, Laravel, react native and many more) 
                                    </p>
                                </div>
                            </div>
                            {/* Our Mission End */}
                        </div>
                        {/* Web Design End */}

                        {/* Graphics Design Start */}
                        <div id='graphicsDesign' 
                            className="row gx-0"
                            ref={(el) => (sections.current.graphicsDesign = el)}
                        >  
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Graphics Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Every Creative & visual task of your business is an opportunity to communicate and engage with your audiences. We develop eye-catching creative output that enables your business to launch, grow and stand out in the marketplace. Our Creative team does the best job for our clients across the world.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We have a team of award-winning graphic designers – constantly inspiring and challenging one another to produce their best. The energy in creatives of our design studio is contagious and the standard is exceptionally high.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Our graphic designers are responsible for creating creative & effective digital messaging, so potential customers understand the values behind a business.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/creative/web_solution_firm_graphics-design.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Graphics Design End */}

                        {/* Logo Design Start */}
                        <div id='logoDesign' 
                            className="row gx-0"
                            ref={(el) => (sections.current.logoDesign = el)}
                        >   
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200" style={{ paddingTop: "20px" }}>
                                <img src="assets/img/services/creative/web_solution_firm_logo.webp" className="img-fluid" alt="" />
                            </div>                       
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Logo Design</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Your Logo tells a story about your company. Every little part of your logo promotes your brand. Designing a logo doesn’t have to be a complicated, but creative process. Our Creative team can design your brand-new eye-catching and memorable logo. We have a dedicated designing team, and we can afford to keep working on your design until you are satisfied.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Our packages are including, but not limited to.
                                    </p>
                                    <p style={{ marginTop:'-20px' }}> 
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                #  Logo Design Concepts x4 <br />
                                                #  Number of Designers x2 <br />
                                                #  Eye Catching Creativity <br />
                                                #  24 Hours Email Support<br />
                                                #  100% Satisfaction Guarantee<br />
                                            </div>
                                            <div className='col-lg-6'>
                                                #  Number of Revisions x 3 <br />
                                                #  8 different File Formats <br />
                                                #  Email Signature Included <br />
                                                #  Business Card Design Included <br />
                                                #  Barcode of your website<br />
                                            </div>
                                        </div>
                                    </p>                                    
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        We design world-class logos that effortlessly convey your message and visually promote your business. Our designs are engaging and memorable, bringing you straight into the minds of your customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Logo Design End */}

                        {/* Brand Identity Start */}
                        <div id='brandIdentity' 
                            className="row gx-0"
                            ref={(el) => (sections.current.brandIdentity = el)}
                        >  
                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                                <div className="content">
                                    <h2>Brand Identity</h2>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Branding is about maximising every opportunity. Brand identity is a set of visual components that represents a company’s ideas and allows customers to remember a brand and distinguish it from dozens of others. It helps shape consumers’ perceptions about a company, build loyalty and trust, and make a brand memorable.
                                    </p>
                                    <p> 
                                        <span className='iconSize'><AiOutlineSlack /></span>
                                        Basically, identity is how your business looks and communicates with potential and existing customers. It includes:
                                    </p>
                                    <p style={{ marginTop:'-20px' }}> 
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                #  Logo  <br />
                                                #  Website  <br />
                                                #  Colour scheme <br />
                                                #  Social media <br />
                                                #  Choice of words <br />
                                                #  Graphics and images<br />
                                                #  Business cards<br />
                                                #  Marketing communications<br />
                                            </div>
                                            <div className='col-lg-6'>
                                                #  Font<br />
                                                #  Tone of voice<br />
                                                #  Slogans and taglines <br />
                                                #  Google Review <br />
                                                #  Product line<br />
                                                #  Packaging<br />
                                                #  Customer service<br />
                                            </div>
                                        </div>
                                    </p> 
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/services/creative/web_solution_firm-brandidentity.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                        {/* Brand Identity End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default CreativePage;