import { useState, useEffect, useRef } from 'react';

const useActiveLink = (initialSections) => {
    const [activeLink, setActiveLink] = useState("");
    const sections = useRef(initialSections);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.6,
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveLink(`#${entry.target.id}`);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        Object.values(sections.current).forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => {
            Object.values(sections.current).forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    return [activeLink, sections];
};

export default useActiveLink;
