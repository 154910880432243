const AddressItem=[
    {
        id:1,
        cat:'nz',
        title:'New Zealand (Head Quater):',
        phone:'+642102362222',
        email: 'masum@websolutionfirm.com',
        address:'29/A Layard Street, Avondale, Auckland 1026',
        address2:'',
        heading:'New Zealand (Head Quarter)',
        bgImage:'assets/img/contactUs/nzoffice.jpg'
    },
    {
        id:2,
        cat:'bd',
        title:'Bangladesh Office:',
        phone:'+8801886780678',
        email: 'info@websolutionfirm.com',
        address:'1283 (1st Floor), East Monipur, Begum Rokeya Sarani Road, ',
        address2:'Mirpur-10, Dhaka-1216.',
        heading:'Bangladesh Office',
        bgImage:'assets/img/contactUs/bdoffice.jpg'
    },
];

export default AddressItem;