import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../../home/home.css';

import { MdDomainVerification } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import useActiveLink from '../../hooks/useActiveLink';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -140; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

const SoftWareDevelopment = () => {
    const [activeLink, sections] = useActiveLink({
        softwareDev: null,
        yourIndustry: null,
        typeOfSoftware: null,
    });

    return (
        <>
            {/* <section className="insidePage" style={{ backgroundImage: `url("assets/img/contact.png")` }}> */}
            <section className="insidePage" style={{ backgroundColor: '#2fb8e7' }}>
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">
                    <p className="text-white">
                        <span className='title text-center'>Software Development</span> 
                        <span className='text-center subTitle'>
                            Let’s build something exceptional together
                        </span>
                    </p>            
                </div>
            </section>

            <main className="main">
                
                {/* ======= Sticky Menu Section ======= */}
                <section id="portfolio" className="portfolio stickyPageMenu" style={{ marginTop:'-50px' }}>
                    <div style={{ backgroundColor:'#090046' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 d-flex ">
                                    <ul id="stickyMenu-flterss">
                                        <Link smooth to='#softwareDev' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#softwareDev" ? "activeTab" : ""}
                                        >
                                            Software Development Services
                                        </Link>

                                        <Link smooth to='#yourIndustry' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#yourIndustry" ? "activeTab" : ""}
                                        >
                                            Your Industry
                                        </Link>

                                        <Link smooth to='#typeOfSoftware' 
                                            scroll={el => scrollWithOffset(el)} 
                                            className={activeLink === "#typeOfSoftware" ? "activeTab" : ""}
                                        >
                                            Types of Software
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ======= Content Section =======  */}
                <section className="about">
                    <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0.9s">

                        {/* Web Design Start */}
                        <div id='softwareDev' 
                            className="row gx-0"
                            ref={(el) => (sections.current.softwareDev = el)}
                        >
                            <section id="services" className="services">
                                <div className="container">
                                    <header className="section-header">
                                        <p className=''>Our Software Development Services</p>
                                    </header>
                                    <div className="row gy-4">
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box orange rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Software consulting</h3>
                                                <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                                                
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box green rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Software UX and UI design</h3>
                                                <p>We are skilled & love solving problems through programming. We have been developing from inventory to ERP solution. Nothing is too small... </p>
                                                
                                            </div>
                                        </div>
                                        
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box purple rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>Software architecture design</h3>
                                            <p>You have an idea to make a mobile app & not sure How to develop it . Don't worry Web Solution Firm  got your back with a dedicated app dev team...</p>
                                            
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box red rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>Software Development</h3>
                                            <p>Every Creative & visual task of your business is an opportunity to communicate with your audiences. whether it’s a website, logo, UX & UI, brochure...</p>
                                            
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box pink rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>API development</h3>
                                            <p>We will develop creative, visual, & data-driven digital marketing to drive your business sales & profit, will look after your all digital Marketing... </p>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box blue rotates">
                                                <i className="icon">
                                                    <MdDomainVerification />
                                                </i>
                                                <h3>Software Testing and QA</h3>
                                                <p>You absolutely deserve a good domain name and the best and most secure server to host your solution on cloud. We have servers across...</p>
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box orange rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>BI Dashbaord & Reporting</h3>
                                                <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                                                
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box green rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Support and maintenance</h3>
                                                <p>We are skilled & love solving problems through programming. We have been developing from inventory to ERP solution. Nothing is too small... </p>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>   
                            </section>                         
                        </div>
                        {/* Web Design End */}

                        {/* Application 1 Start */}
                        <div id='yourIndustry' 
                            className="row gx-0"
                            ref={(el) => (sections.current.yourIndustry = el)}
                        >  
                            <section id="services" className="services">
                                <div className="container">
                                    <header className="section-header">
                                        <p className=''>We Know Your Industry</p>
                                    </header>
                                    <div className="row gy-4">
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box orange rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Manufacturing</h3>
                                                <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                                                
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box green rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Healthcare</h3>
                                                <p>We are skilled & love solving problems through programming. We have been developing from inventory to ERP solution. Nothing is too small... </p>
                                                
                                            </div>
                                        </div>
                                        
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box purple rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>Retail</h3>
                                            <p>You have an idea to make a mobile app & not sure How to develop it . Don't worry Web Solution Firm  got your back with a dedicated app dev team...</p>
                                            
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box red rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>Entertainment</h3>
                                            <p>Every Creative & visual task of your business is an opportunity to communicate with your audiences. whether it’s a website, logo, UX & UI, brochure...</p>
                                            
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box pink rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>Logistics & Transportation</h3>
                                            <p>We will develop creative, visual, & data-driven digital marketing to drive your business sales & profit, will look after your all digital Marketing... </p>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box blue rotates">
                                                <i className="icon">
                                                    <MdDomainVerification />
                                                </i>
                                                <h3>Constructions</h3>
                                                <p>You absolutely deserve a good domain name and the best and most secure server to host your solution on cloud. We have servers across...</p>
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box orange rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Charitable organization</h3>
                                                <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                                                
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box green rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Fintech</h3>
                                                <p>We are skilled & love solving problems through programming. We have been developing from inventory to ERP solution. Nothing is too small... </p>
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box purple rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>EdTech</h3>
                                            <p>You have an idea to make a mobile app & not sure How to develop it . Don't worry Web Solution Firm  got your back with a dedicated app dev team...</p>
                                            
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box red rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>Travel </h3>
                                            <p>Every Creative & visual task of your business is an opportunity to communicate with your audiences. whether it’s a website, logo, UX & UI, brochure...</p>
                                            
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box orange rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Telematics</h3>
                                                <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>   
                            </section> 
                        </div>
                        {/* Application 1 End */}

                        {/* Application 2 Start */}
                        <div id='typeOfSoftware' 
                            className="row gx-0"
                            ref={(el) => (sections.current.typeOfSoftware = el)}
                        >   
                            <section id="services" className="services">
                                <div className="container">
                                    <header className="section-header">
                                        <p className=''>Types of Software We can Develop for you</p>
                                    </header>
                                    <div className="row gy-4">
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box orange rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Saas</h3>
                                                <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                                                
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box green rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>ERP</h3>
                                                <p>We are skilled & love solving problems through programming. We have been developing from inventory to ERP solution. Nothing is too small... </p>
                                                
                                            </div>
                                        </div>
                                        
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box purple rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>CRM</h3>
                                            <p>You have an idea to make a mobile app & not sure How to develop it . Don't worry Web Solution Firm  got your back with a dedicated app dev team...</p>
                                            
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box red rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>Billing</h3>
                                            <p>Every Creative & visual task of your business is an opportunity to communicate with your audiences. whether it’s a website, logo, UX & UI, brochure...</p>
                                            
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box pink rotates">
                                            <i className="icon">
                                                <BiMobile />
                                            </i>
                                            <h3>HR</h3>
                                            <p>We will develop creative, visual, & data-driven digital marketing to drive your business sales & profit, will look after your all digital Marketing... </p>
                                            
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box blue rotates">
                                                <i className="icon">
                                                    <MdDomainVerification />
                                                </i>
                                                <h3>LMS</h3>
                                                <p>You absolutely deserve a good domain name and the best and most secure server to host your solution on cloud. We have servers across...</p>
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box orange rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>Project Management</h3>
                                                <p>If you can imagine it, we can develop it. Don’t leave an idea in the pipeline, let’s convert the idea to a web application. Our highly skilled developers...</p>
                                                
                                            </div>
                                        </div>
                                
                                        <div className="col-lg-3 col-md-6 aos"  data-aos-duration="500" data-aos-delay="0.9s">
                                            <div className="service-box green rotates">
                                                <i className="icon">
                                                    <CgWebsite />
                                                </i>
                                                <h3>e-Commerce</h3>
                                                <p>We are skilled & love solving problems through programming. We have been developing from inventory to ERP solution. Nothing is too small... </p>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>   
                            </section> 
                        </div>
                        {/* Application 2 End */}

                    </div>
                </section>

                {/* ======= End Contact Section ======= */}

            </main>
            {/* <section className=""></section> */}
        </>
    );
};

export default SoftWareDevelopment;