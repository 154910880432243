import React from "react";

function BlogNotFoundCard() {

  return (
    <>
      <div className="entry-meta">
        <p className="mb-0 text-secondary text-center">Data not found.</p>
      </div>
    </>
  );
}

export default BlogNotFoundCard;
