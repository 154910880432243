import React from "react";
import { Link } from "react-router-dom";
import config from "../../../config";

import Category1 from '../../../assets/images/category_icon_1.png';

function IndexContent({ category }) {

  return (
    <>
      <div className="col-12 col-md-3 mb-3">
        <article className="entry pb-1">
          <div className="entry-img" style={{ height: '180px' }}>
            <Link to={`/help-center/knowledged-base/category/${category.slug}`}>
              <img
                src={category.thumbnail ? `${config.IMAGE_BASE_PATH}${category.thumbnail}` : Category1}
                alt={category && category.name}
                className="w-100 h-100"
              />
            </Link>
          </div>
          <h2 className="entry-title">
            <Link to={`/help-center/knowledged-base/category/${category.slug}`}>{category.name}</Link>
          </h2>
        </article>
      </div>
    </>
  );
}

export default IndexContent;
