import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import CompanyMenu from './CompanyMenu';

import '../home.css';
import '../headerMenu.css';

const Company = ({ handleLinkClick }) => {

    const[items] = useState(CompanyMenu);

    return (
        <>         
            <div className="col-lg-12 aboutUsMenu">
                <div className="row aboutUs-cards">
                    {/* <div className="col-lg-12 aboutUs-cards-body">
                        <div className="aboutus-bgImage" style={{ backgroundColor:'rgb(47, 184, 231)'}}>
                            <div className="text-center text-white aboutus-text">
                                <h5><b>Business Transformation</b></h5>
                                <p>Moving your business from Garage to Global</p>
                            </div>
                        </div>
                    </div> */}

                    {
                        items.map((elem) => {
                            const {id, title, desc, image, url} = elem;
                            return (
                                
                                <div className="col-lg-3 col-md-12 col-sm-12 col-12 aboutUs-cards-body" key={id}>
                                    <div className="img">
                                        <Link to={url} onClick={() => handleLinkClick(url)}>
                                            <img src={image} alt="" />
                                        </Link>
                                    </div>
                                    <h5><Link to={url} onClick={() => handleLinkClick(url)}>{title}</Link></h5>
                                    <p>{desc}</p>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </>
    );
};

export default Company;