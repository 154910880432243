import React from "react";
import { Link } from "react-router-dom";

function BlogCard({ blog }) {
  return (
    <>
      <h5 className="entry-title">
        <i className="bi bi-file-earmark-text"></i> { }
        <Link to={`/help-center/knowledged-base/${blog.category.slug}/details/${blog.slug}`}>{blog.title}</Link>
      </h5>
      <hr />
    </>
  );
}

export default BlogCard;
