import React, { useEffect, useState } from 'react'
import "../../../home/home.css";
import BlogCard from './BlogCard';
import BlogSidebar from './BlogSidebar';
import { useParams } from 'react-router-dom';
import api from '../../../api/api';
import BlogNotFoundCard from './BlogNotFoundCard';

function Blogs({ setProgress }) {
    const [blogs, setBlogs] = useState([]);
    const {slug, id} = useParams();

    useEffect(() => {
        setProgress(10)
        api.get(`/blogs/${'blog'}/blog-page`).then((res) => {
            let filteredBlogs;
            if (slug) {
                filteredBlogs = res.data.data.blogs.filter((blog) => blog.category.slug === slug);
            } else if (id) {
                filteredBlogs = res.data.data.blogs.filter((blog) => 
                    blog.tags.some((tag) => tag.id === parseInt(id, 10))
                );
            } else {
                filteredBlogs = res.data.data.blogs;
            }
            setBlogs(filteredBlogs);

            setProgress(100)

        }).catch(err => {
            console.log(err);
        });
    }, [slug, id]);

    return (
        <>
            <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
                <div
                    className="container"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="0.9s"
                >
                    <p className="text-white text-center">
                        <span className="title">Blogs</span>
                        <span className='text-center subTitle'>
                            At Tech Innovators Blog, we dive into the latest in technology
                        </span>
                    </p>
                </div>
            </section>

            <main className='main'>
                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">

                            <div className="col-lg-8 entries">
                                <div className='row'>
                                    {
                                        blogs.length > 0 ? blogs.map(blog => (
                                            <BlogCard blog={blog} key={blog.id}/>
                                        )) : <BlogNotFoundCard/>
                                    }
                                </div>
                            </div>

                            <div className="col-lg-4">

                                <BlogSidebar/>

                            </div>

                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}

export default Blogs