const PortfolioItem=[
    {
        id:1,
        cat:'ecommerce',
        title:'Single vendor Ecommerce',
        desc:'Our eCommerce solution has the features to boost your online growth',
        image: '../../assets/img/solution/eCommerce/web_solution_firm_ecommerce-single-vendor.webp',
        // image: 'assets/img/solution/eCommerce/web_solution_firm_ecommerce-single-vendor.webp',
        url:'ecommerce-solution'
    },
    { 
        id:2,
        cat:'smallbusiness',
        title:'Shop Management system',
        desc:'Cloud-based web application keeps track of all the sales, purchase, bill...',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'small-to-medium-business'
    },
    {
        id:3,
        cat:'ecommerce',
        title:'Multi-Vendor Ecommerce',
        desc:'It’s also known as Marketplace / Multi-Vendor Marketplace...',
        image: '../../assets/img/solution/eCommerce/web_solution_firm_ecommerce-multi-vendor.webp',
        url:'ecommerce-solution'
    },
    {
        id:4,
        cat:'edtech',
        title:'School management system',
        desc:'All in-one system for learning, growth, skills, management...',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'edtech'
    },
    {
        id:5,
        cat:'consultancy',
        title:'IT Consultancy',
        desc:'We help businesses with their  IT strategies and implementation process…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'consultancy'
    },
    {
        id:6,
        cat:'ecommerce',
        title:'Budget Ecommerce',
        desc:'For Small business and want to put your business online…',
        image: '../../assets/img/solution/eCommerce/web_solution_firm_budget_e-commerce.webp',
        url:'ecommerce-solution'
    },
    {
        id:7,
        cat:'consultancy',
        title:'Software Consultancy',
        desc:'We help businesses with their  Software strategies and implementation process…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'consultancy'
    },
    {
        id:8,
        cat:'edtech',
        title:'Online learning management',
        desc:'Unlock the power of learning with LMS. It’s a powerful cloud-based…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'edtech'
    },
    {
        id:10,
        cat:'edtech',
        title:'EAP system',
        desc:'A secure web-based information system that allows organisations…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'edtech'
    },
    {
        id:11,
        cat:'consultancy',
        title:'Data Analytics Consultancy',
        desc:'Data is the new oil and we work with you to establish data driven business culture.',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'consultancy'
    },
    {
        id:12,
        cat:'corporate',
        title:'Asset Booking System',
        desc:'Cloud-based asset management and booking systems for the best…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'corporate'
    },   
    {
        id:14,
        cat:'smallbusiness',
        title:'Inventory management',
        desc:'You track and control your entire business inventory online…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'small-to-medium-business'
    },
    {
        id:15,
        cat:'smallbusiness',
        title:'Asset management',
        desc:'Cloud-based systems are the best way to manage a company asset…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'small-to-medium-business'
    },
    {
        id:16,
        cat:'smallbusiness',
        title:'Trade Business Solution',
        desc:'If you are a trade you must have a look at your online business tools…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'small-to-medium-business'
    },
    {
        id:17,
        cat:'smallbusiness',
        title:'CRM',
        desc:'If you have a list of customers and need to manage in a better way then try this…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'small-to-medium-business'
    },
    {
        id:18,
        cat:'corporate',
        title:'Fleet management with GPS tracking',
        desc:'If you have many vehicles and need smart management with GPS…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'corporate'
    },
    {
        id:19,
        cat:'corporate',
        title:'Meeting Room Booking system',
        desc:' Manage your office and meeting room smartly from anywhere & anytime…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'corporate'
    },
    {
        id:20,
        cat:'corporate',
        title:'ERP Solution',
        desc:'Inhouse developed scalable & highly secure ERP for business solution…',
        image: '../../assets/img/solution/BusinessTransformation.jpg',
        url:'corporate'
    },

];

export default PortfolioItem;