import React, { useEffect, useState } from 'react'
import "../../../home/home.css";
import { useParams } from 'react-router-dom';
import api from '../../../api/api';
import BlogNotFoundCard from './BlogNotFoundCard';
import IndexContent from './IndexContent';

function Index({ setProgress }) {
    const {slug, id} = useParams();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        setProgress(10);
        api.get(`/blogs/${'knowledge_based'}/blog-categories`).then((res) => {
            setCategories(res.data.data.categories);

            setProgress(100);
        });

    }, [slug, id]);

    return (
        <>
            <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
                <div
                    className="container"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="0.9s"
                >
                    <p className="text-white text-center">
                        <span className="title">Knowledgebase</span>
                        <span className='text-center subTitle'>
                            At Tech Innovators Blog, we dive into the latest in technology
                        </span>
                    </p>
                </div>
            </section>


            <main className='main'>
                <section id="blog" className="blog">
                    <div className="container" data-aos="fade-up">
                        <div className="row">

                            <div className="col-lg-12 entries">
                                <div className='row'>
                                    {
                                        categories.length > 0 ? categories.map(category => (
                                            <IndexContent category={category} key={category.id}/>
                                        )) : <BlogNotFoundCard/>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}

export default Index