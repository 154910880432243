import React, { useEffect, useState } from "react";
import "../../../home/home.css";
import BlogSidebar from "./BlogSidebar";
import { useParams } from "react-router-dom";
import api from "../../../api/api";
import config from "../../../config";

const Details = ({ setProgress }) => {
    const [blog, setBlog] = useState();
    const { slug } = useParams();
  
    useEffect(() => {
      setProgress(10);

      api.get(`/blogs/${'blog'}/blog-page`).then((res) => {
        let filterBlog = res.data.data.blogs.filter(blog => blog.slug === slug);
        setBlog(filterBlog[0]);

        setProgress(100);
      });
    }, [slug]);

  return (
    <>
      <section className="insidePage" style={{ backgroundColor: "#2fb8e7" }}>
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="0.9s"
        >
          <p className="text-white">
            <span className="title">Blog Details</span>
          </p>
        </div>
      </section>

      <main className="main">
        <section id="blog" className="blog">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-8 entries">
                <article className="entry entry-single">

                  <h2 className="entry-title">
                    {blog && blog.title}
                  </h2>

                  <div className="entry-meta">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-person"></i>{" "}
                        <span className="blog-author-read-time-wrapper">
                          {blog && blog.created_by.full_name}
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-clock"></i>{" "}
                        <span className="blog-author-read-time-wrapper">
                          <time>{blog && blog.created_at}</time>
                        </span>
                      </li>
                    </ul>
                  </div><hr />
                  
                  <div className="details-img">
                    <img
                      src={`${config.IMAGE_BASE_PATH}${blog ? blog.featured_image : ''}`}
                      alt={blog && blog.title}
                      className="h-100 w-100"
                    />
                  </div>

                  <div className="entry-content">
                    {blog && blog.short_description}
                    <div className="mt-3">
                    <p dangerouslySetInnerHTML={{ __html: blog && blog.description }}></p>
                    </div>
                  </div>

                  <div className="entry-footer">
                    <i className="bi bi-folder"></i>
                    <ul className="cats">
                      <li><span>{blog && blog.category.name}</span></li>
                    </ul>

                    <i className="bi bi-tags"></i>
                    <ul className="tags">
                      {blog && blog.tags.map((tag) => (
                        <li><span>{tag.name}</span></li>
                      ))}
                    </ul>
                  </div>

                </article>
              </div>

              <div className="col-lg-4">
                <div>
                    <BlogSidebar blog={blog}/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Details;
